// @file Library upsell pinia store
import { trackEvent } from '@@/bits/analytics'
import { calculateLibraryPlanTotalPrice, normalizedPaymentSchedule, SwitchType } from '@@/bits/billing_helper'
import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import { FACE_WITH_ROLLING_EYES_EMOJI } from '@@/bits/emoji'
import { captureFetchException } from '@@/bits/error_tracker'
import { p__, translatePrice, __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { generateLibraryCheckoutUrl, generateLibraryUpgradeConfirmationUrl } from '@@/bits/library_plans_helper'
import { navigateTo, reload, transformCurrentUrl } from '@@/bits/location'
import { poll } from '@@/bits/polling'
import { LibraryBilling as LibraryBillingApi } from '@@/dashboard/padlet_api'
import { HttpCode, LibraryType } from '@@/enums'
import { Checkout as CheckoutApi, LibraryPlan as LibraryPlanApi } from '@@/payment/padlet_api'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import { ConfirmationDialogLayoutVariant, useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type {
  EstimateLibrarySwitchingCostResult,
  Library,
  LibraryId,
  LibraryPlan,
  LibrarySwitchResult,
  LibrarySwitchStatusResult,
} from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

interface HashedLibraryPlan {
  annual: LibraryPlan
  monthly: LibraryPlan
}

type HashedLibraryPlans = Record<'gold', HashedLibraryPlan>

interface FetchLibraryPlansArgs {
  libraryId?: LibraryId
}

export enum UpgradeStep {
  ChooseTier,
  ChooseTierNoNeon,
  ChooseTierPadletQuota,
  ChooseTierFileSizeQuota,
  ScheduleTeamGold,
  EstimateUpgradeGoldPlanCost,
  ConfirmUpgradeGoldPlanMonthly,
  ConfirmUpgradeGoldPlanAnnual,
}

export enum UpgradeSource {
  UntrashWallQuota = 'UntrashWallQuota',
  UnarchiveWallQuota = 'UnarchiveWallQuota',
  CreateTemplate = 'CreateTemplate',
  DashboardQuotaCta = 'DashboardQuotaCta',
  DashboardSettingsClick = 'DashboardSettingsClick',
}

const DEFAULT_PLANS = (): HashedLibraryPlans => ({
  gold: { annual: {} as LibraryPlan, monthly: {} as LibraryPlan },
})

const GENERIC_ERROR_MESSAGE = __('We’re sorry, we were unable to process your request. Please try again later.')

export const useLibraryPlansStore = defineStore('libraryPlans', () => {
  const globalAlertDialogStore = useGlobalAlertDialogStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const windowSizeStore = useWindowSizeStore()
  const plans = ref<HashedLibraryPlans>(DEFAULT_PLANS())
  const xUpgradeModal = ref(false)
  const upgradeStep = ref<UpgradeStep | null>(null)
  const upgradeSource = ref<UpgradeSource | null>(null)
  const library = ref<Library | null>(null)
  const makersCount = ref<number | null>(null)
  const wallsUsed = ref<number | null>(null)
  const upgradeLibrarySlug = ref<string | null>(null)
  const plansFetched = computed<boolean>(() => Object.keys(plans.value.gold.annual).length !== 0)
  const libraryType = computed(() => library.value?.libraryType)
  const isDeprecatedFreeTier = computed(() => library.value?.isDeprecatedFreeTier)
  const isLibrarySubscriptionCancelled = computed(() => library.value?.isPlanCancelled)
  const isLibraryOverQuota = computed(() => library.value?.quota.quotaHit)
  const isLibraryTrialExpired = computed(() => library.value?.isTrialExpired)
  const isProcessingSwitch = ref(false)
  const upgradeError = ref('')

  function translatedPlanPrice() {
    return ({ tier, period, makersCount }: { tier: string; period: string; makersCount: number }): string | null => {
      const plan = plans.value[tier][period]
      if (plan.price == null || plan.currency == null) return null
      return translatePrice(
        calculateLibraryPlanTotalPrice({
          makersCount,
          price: parseFloat(plan.price),
        }),
        plan.currency,
      )
    }
  }

  function translatedPlanAnnualDiscount({ tier, makersCount }: { tier: string; makersCount: number }): string | null {
    const monthlyPlan = plans.value[tier].monthly
    const annualPlan = plans.value[tier].annual
    if (
      monthlyPlan.price == null ||
      monthlyPlan.currency == null ||
      annualPlan.price == null ||
      annualPlan.currency == null
    )
      return null
    const monthlyPlanTotalPrice = calculateLibraryPlanTotalPrice({
      makersCount,
      price: parseFloat(monthlyPlan.price),
    })
    const annualPlanTotalPrice = calculateLibraryPlanTotalPrice({
      makersCount,
      price: parseFloat(annualPlan.price),
    })
    return translatePrice(monthlyPlanTotalPrice * 12 - annualPlanTotalPrice, monthlyPlan.currency)
  }

  async function fetchPlans({ libraryId }: FetchLibraryPlansArgs): Promise<void> {
    try {
      const response =
        libraryId != null ? await LibraryPlanApi.fetchByLibraryId({ libraryId }) : await LibraryPlanApi.fetch()
      const fetchedPlans = response.data as unknown as LibraryPlan[]
      const hashedPlans = {
        gold: { annual: {} as LibraryPlan, monthly: {} as LibraryPlan },
      }
      for (const plan of fetchedPlans) {
        plan.formattedPrice = translatePrice(parseFloat(plan.price), plan.currency)
        hashedPlans[plan.tier][plan.period] = plan
      }
      plans.value = hashedPlans
    } catch (e) {
      captureFetchException(e, { source: 'LibraryPlansFetchPlans' })
    }
  }

  // FETCH PLANS ACTIONS
  async function fetchPlansAndShowQuotaUpsellModal(payload: {
    upgradeStep: UpgradeStep
    upgradeSource: UpgradeSource
    library: Library
  }): Promise<void> {
    resetUpgradeModalState()
    void fetchPlans({ libraryId: payload.library.id })
    setUpgradeLibrarySlug({ librarySlug: payload.library.slug })
    library.value = payload.library

    showUpgradeModal({
      upgradeStep: payload.upgradeStep,
      upgradeSource: payload.upgradeSource,
      makersCount: payload.library.makersCount as number,
      wallsUsed: payload.library.quota.wallsUsed,
    })
  }

  async function fetchPlansAndShowUpgradeModal(payload: {
    upgradeStep: UpgradeStep
    library: Library
    upgradeSource?: UpgradeSource | null
  }): Promise<void> {
    resetUpgradeModalState()
    library.value = payload.library
    void fetchPlans({ libraryId: payload.library.id })

    // We only show the choose tier modal for libraries created before team gold trial is launched
    // and if the library is considered deprecated free tier
    if (!payload.library.isDeprecatedFreeTier) {
      showUpgradeToTeamGoldModal({
        makersCount: payload.library.makersCount as number,
        wallsUsed: payload.library.quota.wallsUsed,
        upgradeSource: payload.upgradeSource,
      })
    } else {
      showUpgradeModal({
        upgradeStep: payload.upgradeStep,
        makersCount: payload.library.makersCount as number,
        wallsUsed: payload.library.quota.wallsUsed,
        upgradeSource: payload.upgradeSource,
      })
    }

    setUpgradeLibrarySlug({ librarySlug: payload.library.slug })
  }

  async function fetchPlansAndNavigateToCheckoutUrl({
    libraryId,
    libraryType,
  }: {
    libraryId: LibraryId
    libraryType?: LibraryType
  }): Promise<void> {
    await fetchPlans({ libraryId })
    navigateTo(generateLibraryCheckoutUrl({ libraryId, libraryType, baseCheckoutUrl: plans.value.gold.annual.url }))
  }

  // UPGRADE MODAL ACTIONS
  function trackUpgradeStep({ step, source }: { step: UpgradeStep; source?: UpgradeSource | null }): void {
    const libraryTypeText = library.value?.libraryType === LibraryType.Team ? 'team' : 'classroom'
    if (step === UpgradeStep.ChooseTier) {
      trackEvent('Billing', `Viewed ${libraryTypeText} membership tiers`, null, null, {
        source: source === UpgradeSource.DashboardQuotaCta ? 'Dashboard quota cta' : 'Self upgrade',
      })
    }
    if (step === UpgradeStep.ChooseTierPadletQuota || step === UpgradeStep.ChooseTierNoNeon) {
      trackEvent('Billing', `Viewed ${libraryTypeText} membership tiers`, null, null, {
        source: 'Padlet quota exceeded',
      })
    }
    if (step === UpgradeStep.ChooseTierFileSizeQuota) {
      trackEvent('Billing', `Viewed ${libraryTypeText} membership tiers`, null, null, {
        source: 'File size quota exceeded',
      })
    }
    if (step === UpgradeStep.ScheduleTeamGold) {
      trackEvent('Billing', 'Chose team Gold plan', null, null, {
        source: source === UpgradeSource.DashboardQuotaCta ? 'Dashboard quota cta' : null,
      })
    }
  }

  function showUpgradeModal(payload: {
    upgradeStep: UpgradeStep
    upgradeSource?: UpgradeSource | null
    makersCount: number
    wallsUsed: number
  }): void {
    makersCount.value = payload.makersCount
    wallsUsed.value = payload.wallsUsed
    upgradeStep.value = payload.upgradeStep
    upgradeSource.value = payload.upgradeSource ?? null
    xUpgradeModal.value = true

    trackUpgradeStep({ step: payload.upgradeStep, source: payload.upgradeSource })
  }

  function showUpgradeToTeamGoldModal(payload: {
    makersCount: number
    wallsUsed: number
    upgradeSource?: UpgradeSource | null
  }): void {
    showUpgradeModal({
      upgradeStep: UpgradeStep.ScheduleTeamGold,
      makersCount: payload.makersCount,
      wallsUsed: payload.wallsUsed,
      upgradeSource: payload.upgradeSource,
    })
  }

  function resetUpgradeModalState(): void {
    makersCount.value = null
    wallsUsed.value = null
    library.value = null
    upgradeError.value = ''
  }

  function closeUpgradeModal(): void {
    xUpgradeModal.value = false
    resetUpgradeModalState()
  }

  async function fetchPlansAndNavigateToStripeCheckoutUrl(payload: {
    libraryId: LibraryId
    nextUrl: string
  }): Promise<void> {
    await fetchPlans({ libraryId: payload.libraryId })

    const response = await CheckoutApi.fetchCheckoutPageUrl({
      libraryId: payload.libraryId.toString(),
      priceId: plans.value.gold.annual.id,
      nextUrl: payload.nextUrl,
    })
    navigateTo(response.checkoutPageUrl)
  }

  function closeUpgradeModalAndResetPlans(): void {
    closeUpgradeModal()
    plans.value = DEFAULT_PLANS()
  }

  function changeUpgradeModalStep(payload: { upgradeStep: UpgradeStep }): void {
    upgradeStep.value = payload.upgradeStep
  }

  async function navigateToStripeCheckoutUrl(payload: {
    plan: LibraryPlan
    libraryId: string
    nextUrl: string
  }): Promise<void> {
    const response = await CheckoutApi.fetchCheckoutPageUrl({
      priceId: payload.plan.id,
      libraryId: payload.libraryId,
      nextUrl: payload.nextUrl,
    })
    navigateTo(response.checkoutPageUrl)
  }

  function setUpgradeLibrarySlug({ librarySlug }: { librarySlug: string }): void {
    upgradeLibrarySlug.value = librarySlug
  }

  const isFetchingEstimatedUpgradeCost = ref(false)
  const estimatedLibraryPlanSwitchingCost = ref<EstimateLibrarySwitchingCostResult | null>(null)

  const displayedEstimatedLibraryPlanSwitchingCost = computed<EstimateLibrarySwitchingCostResult>(() => {
    if (estimatedLibraryPlanSwitchingCost.value == null) {
      return {
        is_refund: false,
        currency_code: '',
        new_plan_cost: '',
        unused_portion: '',
        refund_amount: '',
        amount_due: '',
        invoice_amount: '',
      }
    }
    return estimatedLibraryPlanSwitchingCost.value
  })

  function triggerSwitchingErrorBillingSettingsRedirect(libraryId: LibraryId): void {
    const navigateToLibraryBillingSettingsPage = (): void => {
      navigateTo(
        transformCurrentUrl(
          {},
          { path: '/dashboard/settings/library_billing', search: { library_id: String(libraryId) } },
        ),
      )
    }
    globalConfirmationDialogStore.openConfirmationDialog({
      ...FACE_WITH_ROLLING_EYES_EMOJI,
      layoutVariant: windowSizeStore.isSmallerThanTabletPortrait
        ? ConfirmationDialogLayoutVariant.Drawer
        : ConfirmationDialogLayoutVariant.Large,
      title: __('Upgrade currently unavailable'),
      body: __('We are unable to process this action from this page. Please visit the billing page and try again'),
      confirmButtonText: __('Go to billing'),
      cancelButtonText: __('Cancel'),
      afterConfirmActions: [navigateToLibraryBillingSettingsPage],
      shouldFadeIn: false,
    })
  }

  async function fetchEstimatedUpgradeCost({
    libraryId,
    planId,
  }: {
    libraryId: LibraryId
    planId: string
  }): Promise<void> {
    isFetchingEstimatedUpgradeCost.value = true
    const body = asciiSafeStringify({
      planId,
    })
    try {
      const response: JsonAPIResponse<EstimateLibrarySwitchingCostResult> =
        await LibraryBillingApi.estimateSwitchingCost({ libraryId }, { body })
      const estimatedLibraryPlanSwitchingCostResponse: EstimateLibrarySwitchingCostResult = (
        response?.data as JsonAPIResource<EstimateLibrarySwitchingCostResult>
      )?.attributes
      estimatedLibraryPlanSwitchingCost.value = estimatedLibraryPlanSwitchingCostResponse
    } catch (e) {
      switch (e.status) {
        case HttpCode.UnprocessableEntity:
          upgradeError.value = __(
            'Sorry, we are not able to process your request because your last invoice is unpaid. Please update your payment method and try again.',
          )
          break
        case 406:
          closeUpgradeModal()
          triggerSwitchingErrorBillingSettingsRedirect(libraryId)
          break
        default:
          upgradeError.value = GENERIC_ERROR_MESSAGE
      }
    } finally {
      isFetchingEstimatedUpgradeCost.value = false
    }
  }

  async function showUpgradeGoldPlanModal(payload: {
    libraryId: LibraryId
    makersCount: number
    wallsUsed: number
    paymentSchedule: string
  }): Promise<void> {
    showUpgradeModal({
      upgradeStep: UpgradeStep.EstimateUpgradeGoldPlanCost,
      upgradeSource: UpgradeSource.DashboardSettingsClick,
      makersCount: payload.makersCount,
      wallsUsed: payload.wallsUsed,
    })
    const planId = plans.value.gold[normalizedPaymentSchedule(payload.paymentSchedule)].id
    await fetchEstimatedUpgradeCost({ libraryId: payload.libraryId, planId })
    upgradeStep.value =
      payload.paymentSchedule === 'month'
        ? UpgradeStep.ConfirmUpgradeGoldPlanMonthly
        : UpgradeStep.ConfirmUpgradeGoldPlanAnnual
  }

  async function switchGoldLegacyToGold({
    planId,
    libraryId,
    libraryType,
  }: {
    planId: string
    libraryId: LibraryId
    libraryType: LibraryType
  }): Promise<void> {
    try {
      isProcessingSwitch.value = true
      // Trigger plan switching
      const body = asciiSafeStringify({
        switchType: SwitchType.SwitchImmediately,
        planId,
      })
      const response: JsonAPIResponse<LibrarySwitchResult> = await LibraryBillingApi.switchPlan({ libraryId }, { body })
      const statusLink = (response?.data as JsonAPIResource<LibrarySwitchResult>)?.attributes.status_link

      // Fetch switch status
      const { data }: JsonAPIResponse<LibrarySwitchStatusResult> = await poll({
        pollingUrl: statusLink,
        validationCallback: ({ data }) => data.attributes?.switch_status !== undefined,
        options: {
          intervalSecs: 5,
          maxAttempts: 10,
          method: HTTPMethod.get,
        },
      })
      const status = (data as JsonAPIResource<LibrarySwitchStatusResult>)?.attributes.switch_status

      // Handle switch status
      if (status.error_message !== undefined || !status.is_switched) {
        upgradeError.value = GENERIC_ERROR_MESSAGE
      } else if (status.is_refunded !== undefined && !status.is_refunded) {
        // If the switch was successful but not the refund, show a custom error message
        globalAlertDialogStore.openAlertDialog({
          ...ALERT_ICON,
          title: __('Refund failed!'),
          body: p__(
            'libraryType will either be team or classroom',
            'You have successfully switched the %{libraryType} plan! Unfortunately, your bank is unable to process your refund. We have extended your term end for now.',
            { libraryType },
          ),
          closeButtonText: __('Okay'),
          afterCloseActions: [reload],
        })
      } else {
        // Redirect user to :library_type/upgrade/confirmation
        navigateTo(generateLibraryUpgradeConfirmationUrl({ libraryId, libraryType }))
      }
    } catch (e) {
      upgradeError.value = GENERIC_ERROR_MESSAGE
    } finally {
      isProcessingSwitch.value = false
    }
  }

  return {
    // state
    plans,
    // getters
    plansFetched,
    xUpgradeModal,
    makersCount,
    wallsUsed,
    upgradeStep,
    libraryType,
    upgradeSource,
    isLibrarySubscriptionCancelled,
    isLibraryOverQuota,
    isLibraryTrialExpired,
    isDeprecatedFreeTier,
    library,
    estimatedLibraryPlanSwitchingCost,
    displayedEstimatedLibraryPlanSwitchingCost,
    upgradeError,
    isProcessingSwitch,

    // actions
    changeUpgradeModalStep,
    translatedPlanPrice,
    translatedPlanAnnualDiscount,
    fetchPlans,
    fetchPlansAndShowQuotaUpsellModal,
    fetchPlansAndShowUpgradeModal,
    fetchPlansAndNavigateToCheckoutUrl,
    closeUpgradeModal,
    closeUpgradeModalAndResetPlans,
    navigateToStripeCheckoutUrl,
    fetchPlansAndNavigateToStripeCheckoutUrl,
    showUpgradeToTeamGoldModal,
    setUpgradeLibrarySlug,
    showUpgradeModal,
    resetUpgradeModalState,
    showUpgradeGoldPlanModal,
    switchGoldLegacyToGold,
  }
})

export { UpgradeSource as LibraryUpgradeSource, UpgradeStep as LibraryUpgradeStep }
export type { HashedLibraryPlans }
